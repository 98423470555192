<script setup lang="ts">
const showModalMenu = ref<boolean>(false)

function toggleModalMenu () {
  showModalMenu.value = !showModalMenu.value
}

function preLink () {
  showModalMenu.value = false
}
</script>

<template>
  <div class="modal-menu">
    <button @click="toggleModalMenu" class="menu-btn icon">
      <Icon name="menu" width="20px" />
    </button>
  </div>
  <Teleport to="body">
    <ModalView
      :show="showModalMenu"
      :plain="true"
      class="menu-modal"
    >
      <div class="content">
        <div class="menu-links">
          <NuxtLinkLocale @click="preLink" href="/download" class="icon-text">
            <Icon name="download" width="18px" />
            Download
          </NuxtLinkLocale>

          <NuxtLinkLocale @click="preLink" href="/users" class="icon-text">
            <Icon name="pacman" width="18px" />
            Account
          </NuxtLinkLocale>

          <NuxtLinkLocale @click="preLink" href="/support" class="icon-text">
            <Icon name="help" width="18px" />
            Support
          </NuxtLinkLocale>
          <TryButton @click="preLink" />
        </div>
      </div>
    </ModalView>
  </Teleport>
</template>
