<script setup lang="ts">
import '@stripe/stripe-js'
import '~/assets/styles/layout.scss'

const year = new Date().getFullYear()
const config = useRuntimeConfig()
const { locale } = useI18n()
const { region } = useRegion()
const userState = useUserState()

const regionClass = region === 'china' ? 'china' : 'global'
</script>

<template>
  <div :class="regionClass" class="base">
    <header class="top-bar">
      <NuxtLoadingIndicator />
      <div class="content">
        <NuxtLinkLocale class="logo icon" href="/">
          <div class="pz-logo">
            <img src="/images/logo_mono.svg" alt="Pingzapper Logo" />
            <PZIconWithText />
          </div>
        </NuxtLinkLocale>
        <nav class="main">
          <ModalMenu />
          <div class="menu-links">
            <div class="submenu">
              <a class="icon-text">
                <Icon name="star" size="18px" />
                {{ $t('footer.features') }}
              </a>
              <ul>
                <li><NuxtLinkLocale href="/games">{{ $t('apps.title') }}</NuxtLinkLocale></li>
                <li><NuxtLinkLocale href="/servers">{{ $t('servers.title') }}</NuxtLinkLocale></li>
                <li><NuxtLinkLocale href="/plans">{{ $t('plans.title') }}</NuxtLinkLocale></li>
              </ul>
            </div>
            <NuxtLinkLocale to="/download" class="icon-text">
              <Icon name="download" size="18px" />
              {{ $t('download.title') }}
            </NuxtLinkLocale>
            <NuxtLinkLocale href="/users" class="icon-text">
              <Icon name="pacman" width="18px" />
              {{ $t('users.account') }}
            </NuxtLinkLocale>
            <NuxtLinkLocale href="/support" class="icon-text">
              <Icon name="help" width="18px" />
              {{ $t('support.title') }}
            </NuxtLinkLocale>
            <TryButton shade="medium" />
            <LocaleChanger v-if="region === 'china'" />
          </div>
        </nav>
      </div>
    </header>

    <main class="layout">
      <Suspense>
        <slot />
      </Suspense>
    </main>

    <footer class="layout">
      <div class="pz-logo">
        <img src="/images/logo_mono.svg" alt="Pingzapper Logo" />
      </div>

      <div class="links">
        <div class="features">
          <span class="title">{{ $t('footer.features') }}</span>
          <NuxtLinkLocale href="/games">{{ $t('apps.title') }}</NuxtLinkLocale>
          <NuxtLinkLocale href="/servers">{{ $t('servers.title') }}</NuxtLinkLocale>
          <NuxtLinkLocale href="/plans">{{ $t('plans.title') }}</NuxtLinkLocale>
          <NuxtLinkLocale href="/download">{{ $t('download.title') }}</NuxtLinkLocale>
        </div>
        <div class="account">
          <span class="title">{{ $t('footer.account') }}</span>
          <NuxtLinkLocale href="/sign_up">{{ $t('footer.get_started') }}</NuxtLinkLocale>
          <NuxtLinkLocale href="/users/reset">{{ $t('footer.forgotten_details') }}</NuxtLinkLocale>
          <NuxtLinkLocale href="/affiliates">{{ $t('footer.affiliates') }}</NuxtLinkLocale>
          <NuxtLinkLocale v-if="region === 'global'" href="/resellers">
            {{ $t('footer.resellers') }}
          </NuxtLinkLocale>
        </div>
      </div>

      <div v-if="region !== 'china'" class="connect">
        <span class="title">{{ $t('footer.connect') }}</span>

        <div class="social-media x-list">
          <a href="https://twitter.com/pingzapper" target="_blank">
            <Icon name="twitter" color="rgba(255, 255, 255, 0.85)" size="24px" />
          </a>
          <Icon name="facebook" color="rgba(255, 255, 255, 0.85)" size="24px" />
        </div>
      </div>

      <div class="legal">
        <div>
          &copy; {{ year }} Fyrolabs LLC. {{ $t('footer.all_rights_reserved') }}.
        </div>
        <div>Made in NYC with ❤️</div>
        <div>
          <NuxtLinkLocale href="/terms">{{ $t('footer.terms_of_service') }}</NuxtLinkLocale>
          <NuxtLinkLocale href="/privacy">{{ $t('footer.privacy_policy') }}</NuxtLinkLocale>
        </div>
      </div>
    </footer>
  </div>
</template>
